@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap);
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: inherit;
}
.item1 {
    border: none;
    background-color: inherit;
    color: rgb(103, 214, 118);
    padding-left: 10px;
    font-size: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
}
.item1:hover {
    text-decoration: none;
    color: rgb(217, 231, 19);
}
.item1:focus {
    outline: 0;
}
.item2 {
    background: rgba(49, 49, 54, 0.993);
    color: rgb(190, 190, 190);
    margin: 1px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item3 {
    font-size: 20px;
    text-decoration: none;
    background: #292a30;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item3:hover {
    color: inherit;
    background-color: #000000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.item4 {
    font-size: 20px;
    background: #25252b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item4:hover {
    color: inherit;
    background-color: #4267b2;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.item5 {
    font-size: 20px;
    background: #202025;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item5:hover {
    color: inherit;
    background-color: #b91818;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.footer-container {
    color: aliceblue;
    text-align: center;
    background-color: rgba(49, 49, 54, 0.993);
    font-size: 15px;
    width: 100%;
    height: 4rem;
    display: grid;
    grid-template-columns: 3fr 6fr 1fr 1fr 1fr;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .item1 {
        font-size: 12px;
        padding-left: 3px;
    }
    .item2 {
        font-size: 7px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .item1 {
        font-size: 14px;
        padding-left: 5px;
    }
    .item2 {
        font-size: 9px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .item1 {
        font-size: 16px;
        padding-left: 6px;
    }
    .item2 {
        font-size: 11px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .item1 {
        font-size: 18px;
        padding-left: 8px;
    }
    .item2 {
        font-size: 13px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .item1 {
        font-size: 20px;
        padding-left: 10px;
    }
    .item2 {
        font-size: 15px;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .rgb-title {
        width: 85px;
        height: 28px;
        margin: 5px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .rgb-title {
        width: 100px;
        height: 35px;
        margin: 6px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .rgb-title {
        width: 130px;
        height: 40px;
        margin: 7px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .rgb-title {
        width: 150px;
        height: 45px;
        margin: 8px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .rgb-title {
        width: 170px;
        height: 50px;
        margin: 8px;
    }
}

.center {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .text1 {
        font-family: "Josefin Sans", sans-serif;
        width: 400px;
        font-size: 12px;
        margin: 1px;
        line-height: 1.5em;
    }
    .text2 {
        font-family: "Josefin Sans", sans-serif;
        width: 250px;
        font-size: 11px;
        margin: 5px;
        text-align: center;
        line-height: 1.5em;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .text1 {
        font-family: "Josefin Sans", sans-serif;
        width: 400px;
        font-size: 13px;
        margin: 2px;
        line-height: 1.5em;
    }
    .text2 {
        font-family: "Josefin Sans", sans-serif;
        width: 300px;
        font-size: 12px;
        margin: 7px;
        text-align: center;
        line-height: 1.5em;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .text1 {
        font-family: "Josefin Sans", sans-serif;
        width: 450px;
        font-size: 15px;
        margin: 4px;
        line-height: 1.5em;
    }
    .text2 {
        font-family: "Josefin Sans", sans-serif;
        width: 300px;
        font-size: 13px;
        margin: 7px;
        text-align: center;
        line-height: 1.5em;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .text1 {
        font-family: "Josefin Sans", sans-serif;
        width: 500px;
        font-size: 16px;
        margin: 6px;
        line-height: 1.5em;
        text-align: center;
    }
    .text2 {
        font-family: "Josefin Sans", sans-serif;
        width: 400px;
        font-size: 15px;
        margin: 10px;
        text-align: center;
        line-height: 1.5em;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .title-text {
        font-size: 40px;
    }
    .text1 {
        width: 600px;
        font-size: 20px;
        margin: 8px;
        line-height: 1.5em;
    }
    .text2 {
        font-family: "Josefin Sans", sans-serif;
        width: 400px;
        font-size: 16px;
        margin: 10px;
        text-align: center;
        line-height: 1.5em;
    }
}

.tree-size {
    width: 400px;
    height: 140px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .tree-size {
        width: 390px;
        height: 147px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .tree-size {
        width: 410px;
        height: 155px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .tree-size {
        width: 420px;
        height: 155px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .tree-size {
        width: 450px;
        height: 170px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .tree-size {
        width: 470px;
        height: 180px;
    }
}

.customModal {
    background: rgb(238, 237, 237);
    max-width: 550px;
    min-width: 320px;
    width: 100%;
}
.play-btn {
    color: green;
    border: none;
    background-color: inherit;
    padding: 0px;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
}
.play-btn:hover {
    text-decoration: none;
    color: rgb(231, 19, 19);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .play-btn {
        font-family: "Josefin Sans", sans-serif;
        font-size: 11px;
        text-align: center;
    }
    .customModal {
        max-width: 320px;
    }
    .responsive-iframe {
        width: 280px;
        height: 140px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .play-btn {
        font-family: "Josefin Sans", sans-serif;
        font-size: 12px;
        text-align: center;
    }
    .customModal {
        max-width: 410px;
    }
    .responsive-iframe {
        width: 358px;
        height: 205px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .play-btn {
        font-family: "Josefin Sans", sans-serif;
        font-size: 13px;
        text-align: center;
    }
    .customModal {
        max-width: 530px;
    }
    .responsive-iframe {
        width: 480px;
        height: 270px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .play-btn {
        font-family: "Josefin Sans", sans-serif;
        font-size: 15px;
        text-align: center;
    }
    .customModal {
        max-width: 690px;
    }
    .responsive-iframe {
        width: 640px;
        height: 360px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .play-btn {
        font-family: "Josefin Sans", sans-serif;
        font-size: 16px;
        text-align: center;
    }
    .customModal {
        max-width: 850px;
    }
    .responsive-iframe {
        width: 800px;
        height: 450px;
    }
}

body {
    margin: 0;
    background-color: whitesmoke;
    margin: 0;
    padding: 0em;
    overflow: none;
    font-weight: medium;
    text-align: center;
    font-family: Poppins, Arial, sans-serif;
}

h1 {
    color: rgb(22, 21, 21);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 35px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 1px;
    margin: 0;
}

p {
    text-align: center;
    color: #383636;
    font-family: "Verdana", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-indent: 30px;
    margin: 0;
}

.App {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}
.content-wrap {
    padding-bottom: 4rem;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4rem;
}

#logo {
    margin-top: 15px;
    margin-bottom: 2px;
}
.start-button {
    position: relative;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;

    display: block;
    box-sizing: border-box;

    border: 2px solid rgb(109, 180, 118);
    background-color: transparent;
    text-transform: uppercase;
    color: rgb(79, 124, 73);
    text-align: center;
    -webkit-transition: background-color 300ms ease-in-out;
    transition: background-color 300ms ease-in-out;
}
.start-button-size {
    font-size: 20px;
    width: 150px;
    height: 50px;
}
.start-button:hover {
    color: rgb(255, 255, 255);
    border: 2px solid rgb(48, 112, 57);
    background-color: rgb(26, 95, 36);
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none;
}

.top-space {
    margin-top: 15vh;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .start-button-size {
        font-size: 15px;
        width: 100px;
        height: 40px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .start-button-size {
        font-size: 17px;
        width: 110px;
        height: 42px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .start-button-size {
        font-size: 18px;
        width: 120px;
        height: 45px;
    }
}

