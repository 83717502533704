@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import "../node_modules/react-modal-video/scss/modal-video.scss";
body {
    margin: 0;
    background-color: whitesmoke;
    margin: 0;
    padding: 0em;
    overflow: none;
    font-weight: medium;
    text-align: center;
    font-family: Poppins, Arial, sans-serif;
}

h1 {
    color: rgb(22, 21, 21);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 35px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 1px;
    margin: 0;
}

p {
    text-align: center;
    color: #383636;
    font-family: "Verdana", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-indent: 30px;
    margin: 0;
}

.App {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}
.content-wrap {
    padding-bottom: 4rem;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4rem;
}

#logo {
    margin-top: 15px;
    margin-bottom: 2px;
}
.start-button {
    position: relative;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;

    display: block;
    box-sizing: border-box;

    border: 2px solid rgb(109, 180, 118);
    background-color: transparent;
    text-transform: uppercase;
    color: rgb(79, 124, 73);
    text-align: center;
    transition: background-color 300ms ease-in-out;
}
.start-button-size {
    font-size: 20px;
    width: 150px;
    height: 50px;
}
.start-button:hover {
    color: rgb(255, 255, 255);
    border: 2px solid rgb(48, 112, 57);
    background-color: rgb(26, 95, 36);
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none;
}

.top-space {
    margin-top: 15vh;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .start-button-size {
        font-size: 15px;
        width: 100px;
        height: 40px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .start-button-size {
        font-size: 17px;
        width: 110px;
        height: 42px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .start-button-size {
        font-size: 18px;
        width: 120px;
        height: 45px;
    }
}
