a {
    color: inherit;
}
.item1 {
    border: none;
    background-color: inherit;
    color: rgb(103, 214, 118);
    padding-left: 10px;
    font-size: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
}
.item1:hover {
    text-decoration: none;
    color: rgb(217, 231, 19);
}
.item1:focus {
    outline: 0;
}
.item2 {
    background: rgba(49, 49, 54, 0.993);
    color: rgb(190, 190, 190);
    margin: 1px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item3 {
    font-size: 20px;
    text-decoration: none;
    background: #292a30;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item3:hover {
    color: inherit;
    background-color: #000000;
    transition: 0.4s;
}
.item4 {
    font-size: 20px;
    background: #25252b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item4:hover {
    color: inherit;
    background-color: #4267b2;
    transition: 0.4s;
}
.item5 {
    font-size: 20px;
    background: #202025;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item5:hover {
    color: inherit;
    background-color: #b91818;
    transition: 0.4s;
}

.footer-container {
    color: aliceblue;
    text-align: center;
    background-color: rgba(49, 49, 54, 0.993);
    font-size: 15px;
    width: 100%;
    height: 4rem;
    display: grid;
    grid-template-columns: 3fr 6fr 1fr 1fr 1fr;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .item1 {
        font-size: 12px;
        padding-left: 3px;
    }
    .item2 {
        font-size: 7px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .item1 {
        font-size: 14px;
        padding-left: 5px;
    }
    .item2 {
        font-size: 9px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .item1 {
        font-size: 16px;
        padding-left: 6px;
    }
    .item2 {
        font-size: 11px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .item1 {
        font-size: 18px;
        padding-left: 8px;
    }
    .item2 {
        font-size: 13px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .item1 {
        font-size: 20px;
        padding-left: 10px;
    }
    .item2 {
        font-size: 15px;
    }
}
